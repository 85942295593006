.privacy {
  @include PAGE_GRID;
  grid-gap: 0;
  height: 0;
  overflow: hidden;
  h2 {
    @include H3_FONTSTYLE;
    @include MODULE_SPACE;
    @include SMALL_MODULE_SPACE(margin-bottom);
    position: relative;
    grid-column: 3/-3;
    color: $WHITE;
    justify-self: center;
  }
  &-sections {
    @include MODULE_PADDING;
    @include MODULE_SPACE(margin-bottom);
    grid-column: 3/-3;
    border: 1px solid $LINE_COLOR;
    &-section {
      color: $P_FONTCOLOR;
      @include MODULE_PADDING(margin-top);
      &:first-child {
        margin-top: 0;
      }
      h4 {
        @include P_BOLD_FONTSTLYE;
      }
      p {
        @include P_FONTSTLYE;
      }
      a {
        color: $WHITE;
      }
    }
  }
}
