.take-action {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  &-desc {
    @include P_FONTSTLYE;
    @include MODULE_SPACE;
    @include MODULE_SPACE(margin-bottom);
    @include MAX_P_WIDTH;
    grid-column: 3/-3;
    color: $P_FONTCOLOR;
    justify-self: center;

  }
  &-entry {
    @include P_FONTSTLYE;
    @include SUB_PAGE_GRID(10, "none");
    @include MODULE_PADDING;
    grid-column: 3/-3;
    color: $P_FONTCOLOR;
    border: 1px solid $WHITE;
    border-bottom: none;
    box-sizing: border-box;
    align-items: center;
    &:last-child {
      border-bottom: 1px solid $WHITE;
    }
    &-logo {
      justify-self: center;
      width: 100%;
      max-width: 200px;
      @include RESPOND_UNTIL($MOBILE_LAYOUT_BREAKPOINT) {
        grid-column: 1/-1;
      }
      @include RESPOND_FROM($MOBILE_LAYOUT_BREAKPOINT) {
        grid-column: 1/3;
      }
    }
    &-desc {
      @include RESPOND_UNTIL($MOBILE_LAYOUT_BREAKPOINT) {
        grid-column: 1/-1;
        margin-top: 2em;
      }
      @include RESPOND_FROM($MOBILE_LAYOUT_BREAKPOINT) {
        grid-column: 4/8;
      }
    }
    &-button {
      @include H4_FONTSTYLE;
      display: inline-block;
      padding: 8px 15px;
      color: $DEEP_BLUE;
      justify-self: center;
      background-color: $CYAN;
      text-align: center;
      @include HOVER_EFFECT {
        background-color: $WHITE;
        color: $DEEP_BLUE;
      }
      @include RESPOND_UNTIL($MOBILE_LAYOUT_BREAKPOINT) {
        grid-column: 1/-1;
        margin-top: 2em;
      }
      @include RESPOND_FROM($MOBILE_LAYOUT_BREAKPOINT) {
        grid-column: 9/11;
      }
    }
    &-html {
      @include SMALL_MODULE_SPACE(margin-top);
      background-color: white;
      padding: 20px;
      grid-column: 1/-1;
      box-sizing: border-box;
      border-radius: 14px;
    }
  }
}
