.content {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  grid-gap: 0;
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  .info {
    @include P_FONTSTLYE;
    @include MODULE_SPACE;
    @include MAX_P_WIDTH;
    grid-column: 3/-3;
    color: $P_FONTCOLOR;
    justify-self: center;
  }
}
