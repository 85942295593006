.routes {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  video {
    @include MODULE_SPACE;
    position: relative;
    grid-column: 1/-1;
    width: 100%;
    background-color: $BLACK;
  }
}
