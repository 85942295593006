.language-switch {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  padding: 15px;
  top: 0;
  right: 0;
  a {
    @include H6_FONTSTYLE;
    color: $WHITE;
    @include HOVER_EFFECT {
      color: $DEEP_BLUE;
    }
    &[data-selected] {
      padding-bottom: 2px;
      border-bottom: 2px solid $LINE_COLOR
    }
  }
}
