.people {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  &-vitas {
    @include MODULE_SPACE;
    grid-column: 3/-3;
    @include RESPOND_FROM($BREAKPOINT_768) {
      display: grid;
      grid-gap: 0;
      grid-template-columns: 1fr 1fr;
    }
    &-vita {
      @include RESPOND_UNTIL($BREAKPOINT_768) {
        @include MODULE_SPACE;
        &:first-child {
          margin-top: 0;
        }
      }
      @include RESPOND_FROM($BREAKPOINT_1024) {
        grid-column: auto / span 1;
        display: grid;
        grid-gap: 25x;
        &:nth-child(2n + 1) {
          grid-template-columns: 100px auto;
          border-right: 1px solid $LINE_COLOR;
        }
        &:nth-child(2n + 2) {
          grid-template-columns: auto 100px;
          img {
            order: 1;
          }
        }
      }
      img {
        width: 120px;
        @include RESPOND_FROM($BREAKPOINT_1024) {
          width: 100%;
        }
      }
      &-copy {
        @include RESPOND_FROM($BREAKPOINT_768) {
          @include MODULE_PADDING;
        }
        h4 {
          @include H4_FONTSTYLE;
          color: $WHITE;
          @include RESPOND_UNTIL($BREAKPOINT_768) {
            margin-top: 1em;
          }
        }
        h5 {
          @include H6_FONTSTYLE;
          margin-top: 0.5em;
          opacity: 0.5;
          color: $WHITE;
        }
        p {
          @include P_FONTSTLYE;
          color: $P_FONTCOLOR;
        }
      }
    }
  }
}
