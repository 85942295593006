/* ----------------------------------------------------------------
 * BREAKPOINTS
 * --------------------------------------------------------------*/

$BREAKPOINT_320: 320;
$BREAKPOINT_400: 400;
$BREAKPOINT_500: 500;
$BREAKPOINT_768: 768;
$BREAKPOINT_1024: 1024;
$BREAKPOINT_1440: 1440;
$BREAKPOINT_1920: 1920;

$MOBILE_LAYOUT_BREAKPOINT: $BREAKPOINT_768;

/* ----------------------------------------------------------------
 * PAGE_GRID
 * --------------------------------------------------------------*/

@mixin PAGE_GRID($width: 100vw) {
  --page-gap: 9px;
  --page-margin: 22px;
  --page-computedMargin: calc(var(--page-margin) - var(--page-gap));
  --page-maxColWidth: 130px;
  display: grid;
  width: $width;
  grid-column-gap: var(--page-gap);
  grid-template-columns: var(--page-computedMargin) repeat(12, 1fr) var(--page-computedMargin);
  @include RESPOND_FROM($BREAKPOINT_1024) {
    --page-gap: 15px;
    --page-margin: 48px;
  }
  @include RESPOND_FROM($BREAKPOINT_1920) {
    grid-template-columns: auto repeat(12, var(--page-maxColWidth)) auto;
  }
}

@mixin SUB_PAGE_GRID($numCols, $marginType) {
  @include PAGE_GRID;
  width: 100%;
  @if $marginType == "left" {
    grid-template-columns: var(--page-computedMargin) repeat($numCols, 1fr);
    @include RESPOND_FROM($BREAKPOINT_1920) {
      grid-template-columns: auto repeat($numCols, var(--page-maxColWidth));
    }
  }
  @if $marginType == "right" {
    grid-template-columns: repeat($numCols, 1fr) var(--page-computedMargin);
    @include RESPOND_FROM($BREAKPOINT_1920) {
      grid-template-columns: repeat($numCols, var(--page-maxColWidth)) auto;
    }
  }
  @if $marginType == "none" {
    grid-template-columns: repeat($numCols, 1fr);
    @include RESPOND_FROM($BREAKPOINT_1920) {
      grid-template-columns: repeat($numCols, 1fr);
    }
  }
}

/* ----------------------------------------------------------------
 * MARGINS
 * --------------------------------------------------------------*/

@mixin SECTION_SPACE($property: margin-top) {
  @include FLUID_PROPERTY($property, 100, 250);
}

@mixin MODULE_SPACE($property: margin-top) {
  @include FLUID_PROPERTY($property, 50, 100);
}

@mixin SMALL_MODULE_SPACE($property: margin-top) {
  @include FLUID_PROPERTY($property, 25, 50);
}

@mixin MODULE_PADDING($property: padding) {
  @include FLUID_PROPERTY($property, 30, 40);
}

/* ----------------------------------------------------------------
 * WIDTH
 * --------------------------------------------------------------*/

@mixin MAX_P_WIDTH($property: max-width) {
  @include FLUID_PROPERTY($property, 400, 750);
}
