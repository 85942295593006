.stream {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);
  grid-gap: 0;
  background-color: #00212d;
  box-sizing: border-box;
  position: relative;
  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    background-image: url("/src/content/gallery/Bild6.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.3;
  }
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
    z-index: 1;
  }
  .stream-info {
    @include P_FONTSTLYE;
    @include MODULE_SPACE;
    @include MAX_P_WIDTH;
    z-index: 1;
    grid-column: 3/-3;
    color: $P_FONTCOLOR;
    justify-self: center;
    text-align: center;
  }
  .stream-options {
    grid-column: 3/-3;
    display: flex;
    gap: 20px;
    @include MODULE_SPACE(margin-top);
    justify-content: center;
    z-index: 1;
    flex-direction: column;
    @include RESPOND_FROM($MOBILE_LAYOUT_BREAKPOINT) {
      flex-direction: row;
      .stream-options-button {
        width: 270px;
      }
    }
    .stream-options-button {
      cursor: pointer;
      background-color: white;
      padding: 20px 20px 30px;
      text-align: center;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      img {
        width: 100px;
      }
      @include HOVER_EFFECT {
        transform: scale(1.02);
        background-color: $CYAN;
        .stream-options-button {
          &-label {
            color: $WHITE;
          }
          &-info {
            color: $WHITE;
            opacity: 0.5;
          }
        }
      }
      &-label {
        @include H3_FONTSTYLE;
        text-transform: unset;
        letter-spacing: unset;
        color: $DEEP_BLUE;
      }
      &-info {
        @include FOOTNOTE_FONTSTLYE;
        color: $CYAN;
      }
    }
  }
  .stream-watch-trailer-button-box {
    @include SMALL_MODULE_SPACE;
    grid-column: 2/-2;
    justify-self: center;
  }
  .stream-additional-services {
    grid-column: 2/-2;
    justify-self: center;
    @include MODULE_SPACE(margin-top);
    h3 {
      @include H5_FONTSTYLE;
      color: $WHITE;
      text-align: center;
      @include SMALL_MODULE_SPACE(margin-bottom);
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      @include RESPOND_FROM($MOBILE_LAYOUT_BREAKPOINT) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      li {
        a {
          display: block;
          position: relative;
          pointer-events: all;
          opacity: 0.8;
          transition: opacity 0.1s ease-in-out;
          overflow: hidden;
          border-radius: 8px;
          aspect-ratio: 3;
          width: 120px;
          @include HOVER_EFFECT {
            opacity: 1;
          }
          img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
