.footer {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  @include MODULE_SPACE(padding-top);
  grid-gap: 0;
  &-logo {
      grid-column: 3/-3;
      justify-self: center;
    img {
      width: 100%;
      max-width: 300px;
    }
  }
  &-contact {
    color: $WHITE;
    @include MODULE_SPACE;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
      text-align: center;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 3/8;
      text-align: right;
      padding-right: 20px;
      border-right: 1px solid $LINE_COLOR;
    }
    h4 {
      @include H5_FONTSTYLE;
    }
    ul {
      @include SMALL_MODULE_SPACE;
      @include HOVER_EFFECT {
        a {
          opacity: 0.5;
        }
      }
      li {
        a {
          @include P_FONTSTLYE;
          color: $P_FONTCOLOR;
          transition: opacity 0.2s;
          @include HOVER_EFFECT {
            opacity: 1;
          }
        }
      }
    }
  }
  &-channels {
    color: $WHITE;
    @include MODULE_SPACE;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
      text-align: center;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 8/-3;
      padding-left: 20px;
    }
    h4 {
      @include H5_FONTSTYLE;
    }
    ul {
      @include SMALL_MODULE_SPACE;
      white-space: nowrap;
      @include HOVER_EFFECT {
        img {
          transform: scale(0.9);
          opacity: 0.5;
        }
      }
      li {
        display: inline-block;
        position: relative;
        left: -0.32em;
        a {
          img {
            transition: transform 0.2s, opacity 0.2s;
            width: 30px;
            padding-left: 0.3em;
            padding-right: 0.3em;
          }
          @include HOVER_EFFECT {
            img {
              transform: scale(1.1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &-copyright {
    @include MODULE_SPACE;
    @include FOOTNOTE_FONTSTLYE;
    color: $WHITE;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      order: 1;
      grid-column: 3/-3;
      justify-self: center;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 3/8;
      justify-self: start;
    }
  }
  &-meta-links {
    @include MODULE_SPACE;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
      justify-self: center;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 8/-3;
      justify-self: end;
    }
    @include HOVER_EFFECT {
      a {
        opacity: 0.5;
      }
    }
    a {
      @include FOOTNOTE_FONTSTLYE;
      color: $WHITE;
      transition: opacity 0.2s;
      margin-left: 2em;
      &:first-child {
        margin-left: 0;
      }
      @include HOVER_EFFECT {
        opacity: 1;
      }
    }
  }
}
