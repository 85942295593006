/* ----------------------------------------------------------------
 * BODY
 * --------------------------------------------------------------*/

// head.pug

.ready {
  overflow-x: hidden; // fix for Safari, cause body.style.overflow-x is ignored
  overflow-y: scroll;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: $FONT_OPEN_SANS_REGULAR;
  background-color: $DEEP_BLUE;
  -webkit-text-size-adjust: 100%;
}

/* ----------------------------------------------------------------
 * HEADER
 * --------------------------------------------------------------*/

header {
  @include USE_VIEWPORT_HEIGHT;
}

/* ----------------------------------------------------------------
 * FOOTER
 * --------------------------------------------------------------*/

footer {
  @include SECTION_SPACE(margin-bottom);
}

/* ----------------------------------------------------------------
 * LINKS
 * --------------------------------------------------------------*/

a {
  text-decoration: none;
  &:hover {
  }
}

/* ----------------------------------------------------------------
 * FIGURE
 * --------------------------------------------------------------*/

figure {
  margin: 0;
  padding: 0;
}

/* ----------------------------------------------------------------
 * LISTS
 * --------------------------------------------------------------*/

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* ----------------------------------------------------------------
 * HEADINGS
 * --------------------------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  margin: unset;
  font-weight: unset;
}
