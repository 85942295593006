.dates {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  &-link {
    @include MODULE_SPACE;
    grid-column: 3/-3;
    justify-self: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    a {
      @include H4_FONTSTYLE;
      background-color: transparent;
      border: 1px solid $CYAN;
      color: $CYAN;
      padding: 10px 20px;
      @include HOVER_EFFECT {
        background-color: $CYAN;
        color: $BLACK;
      }
    }
    p {
      @include FOOTNOTE_FONTSTLYE;
      margin-top: 1em;
      text-align: center;
      opacity: 0.5;
      color: $P_FONTCOLOR;
    }
  }

  ul {
    @include MODULE_SPACE;
    display: grid;
    grid-column: 3/-3;
    border: 1px solid $LINE_COLOR;
    border-bottom: none;
    border-right: none;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-template-columns: 1fr;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-template-columns: repeat(2, 1fr);
    }
    .dates-item {
      @include P_FONTSTLYE;
      @include MODULE_PADDING;
      box-sizing: border-box;
      border-bottom: 1px solid $LINE_COLOR;
      border-right: 1px solid $LINE_COLOR;
      color: $P_FONTCOLOR;
      &-date {
        @include P_BOLD_FONTSTLYE;
        display: inline-block;
      }
      &-time {
        @include P_BOLD_FONTSTLYE;
        display: inline-block;
        &:before {
          margin-right: 0.5em;
          margin-left: 0.5em;
          content: "|";
        }
      }
      &-location {
        margin-top: 0.5em;
      }
      &-address {

      }
      &-link {
        @include H6_FONTSTYLE;
        display: inline-block;
        margin-top: 1em;
        border: 1px solid $LINE_COLOR;
        padding: 8px 15px;
        color: $WHITE;
        @include HOVER_EFFECT {
          background-color: $WHITE;
          color: $DEEP_BLUE;
        }
      }
    }
  }
}
