.trailer {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  .vimeo-frame {
    @include MODULE_SPACE;
    position: relative;
    grid-column: 2/-2;
    padding-top: 52.73%;
    background-color: $BLACK;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }
}
