.newsletter {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  grid-gap: 0;
  &-meta {
    @include MODULE_PADDING;
    grid-column: 3/-3;
    border: 1px solid $LINE_COLOR;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 3/8;
    }
    h2 {
      @include H2_FONTSTYLE;
      color: $WHITE;
    }
    p {
      @include P_FONTSTLYE;
      color: $P_FONTCOLOR;
    }
  }
  #mc_embed_signup {
    @include P_FONTSTLYE;
    @include MODULE_PADDING;
    border: 1px solid $LINE_COLOR;
    color: $P_FONTCOLOR;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
      border-top: none;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 8/-3;
      border-left: none;
    }
    .email {
      label {
        @include H6_FONTSTYLE;
        display: block;
        margin-bottom: 1em;
        color: $WHITE;
      }
      input {
        @include P_FONTSTLYE;
        -webkit-appearance: none;
        width: 100%;
        padding: 0.5em 1em;
        box-sizing: border-box;
        border-radius: 0;
      }
    }
    .content__gdpr {
      margin-top: 3em;
      label {
        @include H6_FONTSTYLE;
        display: block;
        margin-bottom: 1em;
        color: $WHITE;
      }
      fieldset {
        display: grid;
        grid-template-columns: 30px auto;
        padding: 0;
        margin: 0;
        border: 0;
        input {
          grid-column: 1 / span 1;
        }
        .checkbox.subfield {
          grid-column: 2 / span 1;
        }
      }
    }
    .footnote {
      @include FOOTNOTE_FONTSTLYE;
      margin-top: 20px !important;
      opacity: 0.5;
      a {
        margin-left: 1em;
        border-bottom: 1px solid $LINE_COLOR;
        color: $WHITE;
      }
    }
    #mc-embedded-subscribe {
      width: 100%;
      padding: 1em 2em;
      margin-top: 3em;
      box-sizing: border-box;
      background-color: $CYAN;
      border: 1px solid transparent;
      border-radius: 0;
      cursor: pointer;
      color: $WHITE;
      -webkit-appearance: none;
      @include H5_FONTSTYLE;
      @include HOVER_EFFECT {
        border: 1px solid $LINE_COLOR;
      }
    }
    .brandingLogo {
      display: none;
      margin-top: 3em !important;
      opacity: 0.2;
      @include HOVER_EFFECT {
        opacity: 1;
      }
      img {
        width: 150px;
      }
    }
  }
}
