.main-title {
  position: absolute;
  display: flex;
  top: 0;
  justify-content: center;
  width: 100vw;
  @include FLUID_PROPERTY(top, 50, 200);
  @include NOT_SELECTABLE;
  &-box {
    h1 {
      @include FLUID_PROPERTY(font-size, 50 * 0.6, 50 * 1.5);
      @include FLUID_PROPERTY(line-height, 46 * 0.6, 46 * 1.5);
      color: $WHITE;
      text-transform: uppercase;
      font-family: $FONT_OPEN_SANS_BOLD;
      letter-spacing: 0.2em;
    }
    h3 {
      @include FLUID_PROPERTY(font-size, 13 * 0.6, 13 * 1.5);
      margin-top: 0.8em;
      margin-right: 0.8em;
      color: $WHITE;
      text-align: right;
      text-transform: uppercase;
      font-family: $FONT_OPEN_SANS_REGULAR;
    }
    &-buttons {
      display: flex;
      justify-content: center;
      margin-top: 45px;
      flex-direction: column;
      align-items: center;
      @include RESPOND_FROM($MOBILE_LAYOUT_BREAKPOINT) {
        gap: 50px;
        flex-direction: row;
        margin-top: 75px;
      }
    }
  }
}
