.festivals {
  @include SECTION_SPACE;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  &-logo {
    display: inline-block;
    @include FLUID_PROPERTY(margin-left, 5, 30, $BREAKPOINT_320);
    @include FLUID_PROPERTY(margin-right, 5, 30, $BREAKPOINT_320);
    img {
      @include FLUID_PROPERTY(width, 80, 250, $BREAKPOINT_320);
    }
  }
}
