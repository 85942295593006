.stage-2d {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-image: url("/src/content/stage/stage-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  img {
    position: absolute;
    width: 100vw;
    top: 0;
    @include RESPOND_UNTIL($BREAKPOINT_1024) {
      @include FLUID_PROPERTY(left, -90, 0, $BREAKPOINT_320, $BREAKPOINT_1024);
      @include FLUID_PROPERTY(width, 500, 1024, $BREAKPOINT_320, $BREAKPOINT_1024);
    }
  }
}
