/******************************************************************
 * UTILS
 *
 * author: matthias.schulz@jash.de
 *****************************************************************/

/* ----------------------------------------------------------------
 * FUNCTIONS
 * --------------------------------------------------------------*/

@function getResponsiveValueCalc($minValue, $maxValue, $minWidth: $BREAKPOINT_320, $maxWidth: $BREAKPOINT_1920) {
    @return calc(#{$minValue}px + #{$maxValue - $minValue} * (100vw - #{$minWidth}px) / #{$maxWidth - $minWidth});
}

/* ----------------------------------------------------------------
 * VIEWPORT
 * --------------------------------------------------------------*/

@mixin USE_VIEWPORT_HEIGHT() {
    height: 100vh;
    max-height: -webkit-fill-available;
}

/* ----------------------------------------------------------------
 * DOM-UTILS
 * --------------------------------------------------------------*/

@mixin HIDE_SCROLLBARS() {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin NOT_SELECTABLE() {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* ----------------------------------------------------------------
 * MIXINS FOR RESPONSIVE STYLES
 * --------------------------------------------------------------*/

@mixin FLUID_PROPERTY($property, $minValue, $maxValue, $minBaseWidth: $BREAKPOINT_320, $maxBaseWidth: $BREAKPOINT_1920) {
    #{$property}: #{$minValue}px;
    @include RESPOND_FROM($minBaseWidth) {
        #{$property}: getResponsiveValueCalc($minValue, $maxValue, $minBaseWidth, $maxBaseWidth);
    }
    @include RESPOND_FROM($maxBaseWidth) {
        #{$property}: #{$maxValue}px;
    }
}

@mixin RESPOND_FROM($breakpoint) {
    @media (min-width: #{$breakpoint}px) {
        @content;
    }
}

@mixin RESPOND_UNTIL($breakpoint) {
    @media (max-width: #{$breakpoint - 1}px) {
        @content;
    }
}

@mixin RESPOND_FROM_HEIGHT($height) {
    @media (min-height: #{$height}px) {
        @content;
    }
}

@mixin RESPOND_UNTIL_HEIGHT($height) {
    @media (max-height: #{$height - 1}px) {
        @content;
    }
}

/* ----------------------------------------------------------------
 * EASINGS
 * --------------------------------------------------------------*/

$EASE_IN_QUINT: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$EASE_OUT_QUINT: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$EASE_INOUT_QUINT: cubic-bezier(0.860, 0.000, 0.070, 1.000);

/* ----------------------------------------------------------------
 * HOVER
 * --------------------------------------------------------------*/

@mixin HOVER_EFFECT() {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
    &:active {
        @content;
    }
}
