.abstract {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  grid-gap: 0;
  &-facts {
    @include P_FONTSTLYE;
    @include MODULE_PADDING;
    color: $P_FONTCOLOR;
    border: 1px solid $LINE_COLOR;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 3/8;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 3/7;
    }
    &-item {
      @include FLUID_PROPERTY(grid-column-gap, 20, 50);
      display: grid;
      grid-template-columns: 80px auto;
      @include RESPOND_FROM($BREAKPOINT_500) {
        grid-template-columns: 120px auto;
      }
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-template-columns: 100px auto;
      }
      margin-top: 0.5em;
      &:first-child {
        margin-top: 0;
      }
      &-label {
        @include P_BOLD_FONTSTLYE;
      }
    }
  }
  &-desc {
    @include P_FONTSTLYE;
    @include MODULE_PADDING;
    color: $P_FONTCOLOR;
    border: 1px solid $LINE_COLOR;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
      justify-self: center;
      border-top: none;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 8/-3;
      border-left: none;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 7/-3;
    }
  }
}
