@mixin NavigationItem() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transition: transform 0.3s, opacity 0.3s;
  content: attr(data-label);
  pointer-events: none;
}

.navigation {
  @include NOT_SELECTABLE;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  &-scrollbox {
    @include HIDE_SCROLLBARS;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      bottom: 0;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      @include FLUID_PROPERTY(bottom, 70, 100, $BREAKPOINT_768);
    }
    position: absolute;
    width: 100%;
    text-align: center;
    transition: background-color 0.2s;
    overflow-x: scroll;
    overflow-y: hidden;
    pointer-events: all;
    -webkit-overflow-scrolling: touch;
  }
  &-items {
    display: inline-block;
    padding: 15px 20px;
    white-space: nowrap;
    background-color: rgba($DEEP_BLUE, 0.8);
    border: 1px solid $LINE_COLOR;
  }
  &-item {
    @include H5_FONTSTYLE;
    @include FLUID_PROPERTY(margin-right, 15, 20);
    @include FLUID_PROPERTY(margin-left, 15, 20);
    pointer-events: all;
    position: relative;
    display: inline-block;
    color: rgba($WHITE, 0);
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.2s, border 0.2s;
    top: -2px;
    &:before {
      @include NavigationItem;
      color: $WHITE;
      transform: translateY(0);
    }
    &:after {
      @include NavigationItem;
      opacity: 0;
      color: $CYAN;
      transform: translateY(33%);
    }
    @include HOVER_EFFECT {
      &:before {
        opacity: 0;
        transform: translateY(-25%);
      }
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

}
