.imprint {
  @include PAGE_GRID;
  grid-gap: 0;
  height: 0;
  overflow: hidden;
  h2 {
    @include H3_FONTSTYLE;
    @include MODULE_SPACE;
    @include SMALL_MODULE_SPACE(margin-bottom);
    position: relative;
    grid-column: 3/-3;
    color: $WHITE;
    justify-self: center;
  }
  &-contact {
    @include MODULE_PADDING;
    @include P_FONTSTLYE;
    color: $P_FONTCOLOR;
    border-top: 1px solid $LINE_COLOR;
    border-left: 1px solid $LINE_COLOR;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
      border-right: 1px solid $LINE_COLOR;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 3/8;
    }
    ul {
      margin-top: 2em;
      @include HOVER_EFFECT {
        a {
          opacity: 0.5;
        }
      }
      li {
        display: grid;
        margin-top: 0.5em;
        grid-template-columns: 100px auto;
        h4 {
          @include P_BOLD_FONTSTLYE;
        }
        a {
          color: $WHITE;
          transition: opacity 0.2s;
          @include HOVER_EFFECT {
            opacity: 1;
          }
        }
      }
    }
  }
  &-partner {
    @include MODULE_PADDING;
    @include P_FONTSTLYE;
    color: $P_FONTCOLOR;
    border-top: 1px solid $LINE_COLOR;
    border-left: 1px solid $LINE_COLOR;
    border-right: 1px solid $LINE_COLOR;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-column: 3/-3;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 8/-3;
    }
    &-block {
      @include SMALL_MODULE_SPACE;
      &:first-child {
        margin-top: 0;
      }
      h4 {
        @include P_BOLD_FONTSTLYE;
        margin-bottom: 0.5em;
      }
      p {
        &:first-child {
          margin-top: 0;
        }
        a {
          color: $WHITE;
          opacity: 0.7;
          @include HOVER_EFFECT {
            opacity: 1;
          }
        }
      }
    }

  }
  &-disclaimer {
    @include MODULE_PADDING;
    @include MODULE_SPACE(margin-bottom);
    @include P_FONTSTLYE;
    grid-column: 3/-3;
    border: 1px solid $LINE_COLOR;
    color: $P_FONTCOLOR;
    h4 {
      @include P_BOLD_FONTSTLYE;
      margin-bottom: 0.5em;
    }
  }
}
