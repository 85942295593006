.fact {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  &-title {
    @include H6_FONTSTYLE;
    position: relative;
    display: inline-block;
    grid-column: 3/-3;
    color: rgba($WHITE, 0.5);
    text-align: center;
    &:before {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 1px;
      margin-left: -80px;
      background-color: rgba($WHITE, 0.5);
      content: " ";
    }
    &:after {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 1px;
      margin-left: 30px;
      background-color: rgba($WHITE, 0.5);
      content: " ";
    }
  }
  &-desc {
    @include QUOTE_STYLE;
    @include SMALL_MODULE_SPACE;
    grid-column: 3/-3;
    color: $CYAN;
    text-align: center;
    max-width: 1200px;
    justify-self: center;
  }
  &-source {
    @include H6_FONTSTYLE;
    @include SMALL_MODULE_SPACE;
    grid-column: 3/-3;
    color: rgba($WHITE, 0.5);
    text-align: center;
    @include HOVER_EFFECT {
      color: rgba($WHITE, 1);
    }
  }
}
