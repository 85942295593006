.funds {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  grid-gap: 0;
  h2 {
    @include H2_FONTSTYLE;
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  &-sponsors {
    @include MODULE_SPACE;
    grid-column: 3/-3;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    &-sponsor {
      @include FLUID_PROPERTY(margin-left, 15, 80, $BREAKPOINT_320);
      @include FLUID_PROPERTY(margin-right, 15, 80, $BREAKPOINT_320);
      mix-blend-mode: luminosity;
      @include HOVER_EFFECT {
        mix-blend-mode: normal;
      }
      img {
        @include FLUID_PROPERTY(width, 70, 200, $BREAKPOINT_320);
      }
    }
  }
}
