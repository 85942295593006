.gallery {
  @include PAGE_GRID;
  @include SECTION_SPACE;
  @include HOVER_EFFECT {
    img {
      opacity: 0.5;
    }
  }
  h2 {
    @include H2_FONTSTYLE;
    @include MODULE_SPACE(margin-bottom);
    position: relative;
    grid-column: 2/-2;
    color: $WHITE;
    justify-self: center;
  }
  &-images {
    display: grid;
    grid-column: 1/-1;
    @include RESPOND_UNTIL($BREAKPOINT_768) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-template-columns: repeat(4, 1fr);
    }
    &-image {
      position: relative;
      width: 100%;
      padding-bottom: 66%;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        transition: transform 0.15s, box-shadow 0.15s, opacity 0.15s;
        @include HOVER_EFFECT {
          z-index: 1;
          box-shadow: 0 0 10px rgba(black, 0.5);
          opacity: 1;
          transform: scale(1.01);
        }
      }
    }
  }
}
