/* ----------------------------------------------------------------
 * FONTS
 * --------------------------------------------------------------*/

$FONT_OPEN_SANS_REGULAR: 'OpenSans-Regular';
$FONT_OPEN_SANS_SEMIBOLD: 'OpenSans-Semibold';
$FONT_OPEN_SANS_BOLD: 'OpenSans-Bold';
$FONT_NOTO_SERIF_LIGHTITALIC: 'NotoSerifDisplay-LightItalic';

@font-face {
  font-family: $FONT_OPEN_SANS_REGULAR;
  src: url("/src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: $FONT_OPEN_SANS_SEMIBOLD;
  src: url("/src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: $FONT_OPEN_SANS_BOLD;
  src: url("/src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: $FONT_NOTO_SERIF_LIGHTITALIC;
  src: url("/src/assets/fonts/Noto_Serif_Display/static/NotoSerifDisplay/NotoSerifDisplay-LightItalic.ttf") format("truetype");
}

body {
  text-rendering: geometricPrecision;
}

/* ----------------------------------------------------------------
 * FONT STYLES
 * --------------------------------------------------------------*/

@mixin H2_FONTSTYLE {
  font-family: $FONT_OPEN_SANS_BOLD;
  @include FLUID_PROPERTY(font-size, 18, 36);
  @include FLUID_PROPERTY(line-height, 20, 40);
  text-transform: uppercase;
}

@mixin H3_FONTSTYLE {
  font-family: $FONT_OPEN_SANS_BOLD;
  @include FLUID_PROPERTY(font-size, 16, 22);
  @include FLUID_PROPERTY(line-height, 18, 26);
  text-transform: uppercase;
}

@mixin H4_FONTSTYLE {
  font-family: $FONT_OPEN_SANS_BOLD;
  @include FLUID_PROPERTY(font-size, 14, 18);
  @include FLUID_PROPERTY(line-height, 18, 22);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin H5_FONTSTYLE {
  font-family: $FONT_OPEN_SANS_SEMIBOLD;
  @include FLUID_PROPERTY(font-size, 12, 16);
  @include FLUID_PROPERTY(line-height, 18, 22);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin H6_FONTSTYLE {
  font-family: $FONT_OPEN_SANS_BOLD;
  @include FLUID_PROPERTY(font-size, 12, 14);
  @include FLUID_PROPERTY(line-height, 12, 14);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin P_FONTSTLYE {
  font-family: $FONT_OPEN_SANS_REGULAR;
  @include FLUID_PROPERTY(font-size, 13, 18);
  @include FLUID_PROPERTY(line-height, 25, 30);
  hyphens: auto;
  p {
    margin-top: 1.8em;
    margin-bottom: 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
}
@mixin P_BOLD_FONTSTLYE {
  font-family: $FONT_OPEN_SANS_BOLD;
  @include FLUID_PROPERTY(font-size, 12, 18);
  @include FLUID_PROPERTY(line-height, 18, 26);
}


@mixin FOOTNOTE_FONTSTLYE {
  font-family: $FONT_OPEN_SANS_REGULAR;
  @include FLUID_PROPERTY(font-size, 12, 14);
  @include FLUID_PROPERTY(line-height, 16, 18);
}

@mixin QUOTE_STYLE {
  font-family: $FONT_NOTO_SERIF_LIGHTITALIC;
  @include FLUID_PROPERTY(font-size, 30, 70);
}
